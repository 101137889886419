import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';

import { AuthService, StorageService } from './services/all';
import { environment } from 'environments/environment';

export function AppInitializerFactory(
    translateService: TranslateService,
    storageService: StorageService,
    injector: Injector,
    authService: AuthService
) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(
                LOCATION_INITIALIZED,
                Promise.resolve(null)
            );

            locationInitialized.then(() => {
                const token = localStorage.getItem("auth.token");
                let loggedUser$ = Promise.resolve(null); // Default promise if there's no token

                // Only call getLoggedUser if there's a valid token
                if (token && token !== "undefined") {
                    loggedUser$ = authService.getLoggedUser().toPromise();
                }

                loggedUser$.then(() => {
                    let preferredLanguage = storageService.getItem('lang') ?? 'bs';
                    if (preferredLanguage) {
                        translateService.use(preferredLanguage);
                    } else {
                        storageService.setItem('lang', environment.defaultLanguage);
                        translateService.use(environment.defaultLanguage);
                        preferredLanguage = environment.defaultLanguage;
                    }

                    translateService.setDefaultLang(preferredLanguage);
                    translateService.use(preferredLanguage).subscribe({
                        next: () => {
                            if (!environment.production) {
                                console.info();
                            }
                        },
                        error: () => {
                            if (!environment.production) {
                                console.error();
                            }
                        },
                        complete: () => {
                            resolve(null); // Complete initialization
                        },
                    });
                });
            });
        });
}
