import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import {
    PagedListModel,
    ChildrenPagedModel,
    PersonModel,
} from 'app/models/all';
import { ChildrenPagedRequest, GetByIdRequest } from 'app/models/requests/all';
import { Observable, observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PersonsService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    getChildrenFiltered(request: ChildrenPagedRequest) {
        return this.sendGetRequest<PagedListModel<ChildrenPagedModel>>(
            'persons/children_filter',
            request
        );
    }

    updateChildPhoto(request: FormData) {
        return this.sendFormRequest<any>('persons/update-child-photo', request);
    }

    getPersonById(request: GetByIdRequest) {
        return this.sendGetRequest<any>('persons', request);
    }

    getPersonModalData(request: GetByIdRequest) {
        return this.sendGetRequest<any>(
            'persons/get-person-modal-data',
            request
        );
    }

    getDetailedById(request: GetByIdRequest) {
        return this.sendGetRequest<PersonModel>(
            'persons/get-detailed',
            request
        );
    }
}
