import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { NotificationService, SignalRService } from 'app/services/all';
import {
    NotificationsDeleteRequest,
    NotificationsGetRequest,
    NotificationsMarkAllAsReadRequest,
    NotificationsMarkAsReadRequest,
} from 'app/models/requests/notification.request';
import { NotificationModel, PagedListModel } from 'app/models/all';
import { Store } from '@ngxs/store';
import { AuthState } from 'app/stores/all';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { IndexComponent } from './index/index.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel')
    private _notificationsPanel: TemplateRef<any>;

    notifications: NotificationModel[];
    unreadCount: number;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _store: Store,
        private _dialog: MatDialog,
        private _signalR: SignalRService,
        private _translateService: TranslateService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */

    ngOnInit(): void {
        this.loadData();
        this._signalR.HubConnection.on(
            'sendnotification',
            (result: any) => {
                this.loadData();
            }
        );

        this._signalR.HubConnection.on('Delete', (result: any) => {
            this.loadData();
        });

        this._signalR.HubConnection.on(
            'NotificationsCount',
            (result: any) => {
                this.unreadCount = result;
            }
        );

        this._signalR.HubConnection.on(
            'MarkAsRead',
            (result: any) => {
                this.loadData();
            }
        );

        this._signalR.HubConnection.on(
            'MarkAllAsRead',
            (result: any) => {
                this.loadData();
            }
        );

        this._signalR.HubConnection.on(
            'NotificationsCount',
            (result: any) => {
                this.unreadCount = result;
            }
        );

        // Subscribe to notification changes
    }

    loadData() {
        this._notificationsService
            .get(
                new NotificationsGetRequest(
                    this._store.selectSnapshot(AuthState.details).Id,
                    null,
                    null,
                    null
                )
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: PagedListModel<NotificationModel>) => {
                // Load the notifications
                this.notifications = notifications?.items;
                this.unreadCount = notifications?.items.filter(
                    (item) => item.readed == false
                ).length;
                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    getParsed(value: string): object {
        const parsedObject = JSON.parse(value);
        for (const key in parsedObject) {
            if (parsedObject.hasOwnProperty(key)) {
                const currentValue = parsedObject[key];
                if (this.isJsonParsable(currentValue)) {
                    try {
                        const innerObject = JSON.parse(currentValue);
                        if (innerObject.hasOwnProperty('value') && typeof innerObject.value === 'string') {
                            parsedObject[key] = this._translateService.instant(innerObject.value).toLowerCase();
                        }
                    } catch (error) {
                        console.warn(`Failed to parse JSON string for key: ${key}`, error);
                        parsedObject[key] = currentValue;
                    }
                }
            }
        }
        return parsedObject;
    }

    isJsonParsable(value: any): boolean {
        if (typeof value !== 'string') {
          return false;
        }
        
        try {
          JSON.parse(value);
          return true;
        } catch (e) {
          return false;
        }
      }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
        );
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        this._notificationsService
            .markAllAsRead(
                new NotificationsMarkAllAsReadRequest(
                    this._store.selectSnapshot(AuthState.details).Id
                )
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: NotificationModel): void {
        // Toggle the read status
        const loggedUserId = this._store.selectSnapshot(AuthState.details).Id;
        this._notificationsService
            .markAsRead(
                new NotificationsMarkAsReadRequest(
                    null,
                    notification.id,
                    loggedUserId,
                    !notification.readed
                )
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.loadData();
            });
    }

    /**
     * Delete the given notification
     */
    delete(notificationId: number): void {
        const loggedUserId = this._store.selectSnapshot(AuthState.details).Id;

        // Delete the notification
        this._notificationsService
            .remove(
                new NotificationsDeleteRequest(loggedUserId, notificationId)
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.loadData();
            });
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._notificationsOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    getNotificationIcon(type: number): string {
        if (type == 1) return '/assets/img/annoucements/info.png';
        if (type == 2) return '/assets/img/annoucements/calendar.png';
        if (type == 3) return '/assets/img/annoucements/warning.png';
        if (type == 4) return '/assets/img/annoucements/request.png';
    }

    getRelativeFormat(date: string): string {
        return DateTime.fromISO(date).toRelativeCalendar();
    }

    openAll() {
        const dialogRef = this._dialog.open(IndexComponent, {
            data: this.notifications,
            disableClose: true,
            maxWidth: '512px',
            maxHeight: '100vh',
            width: '95%',
        });

        this.closePanel();
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {}
}
