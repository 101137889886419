import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { activeRole, institutionId } from 'app/constants/constants';
import { AuthState } from 'app/stores/all';

@Injectable({
    providedIn: 'root',
})
export class StorageService implements Storage {
    private readonly storage: Storage;

    constructor(private store: Store, private router: Router) {
        this.storage = window.localStorage;
    }

    get length(): number {
        return this.storage.length;
    }

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string | null {
        const item = this.storage.getItem(key);
        const user = this.store.selectSnapshot(AuthState.details);
        if (user == null) {
            return;
        }
        if (key == activeRole) {
            if (user.ActiveRole?.toString() != item?.slice(1, -1)) {
                this.router.navigate(['/401-unauthorized'], { state : { goBack: -1 } });
                return;
            }
        }
        if (key == institutionId) {
            const roles = user.AspNetUserRoles;
            const isAdmin = roles.find((c) => !c.InstitutionId);
            if (
                isAdmin == null &&
                !roles.find((entity) => entity.InstitutionId.toString() == item)
                ) {
                this.router.navigate(['/401-unauthorized'], { state : { goBack: -1 } });
                return;
            }
        }
        return item;
    }

    // *[Logged user instutiton]*
    getInstitutionId(): number | null {
        const value = Number(this.getItem(institutionId));

        if (value == 0 || isNaN(value)) return null;
        else return value;
    }

    // *[Logged user active role]*

    getActiveRole(): string {
        if (
            this.getItem(activeRole) != null ||
            this.getItem(activeRole) != undefined
        ){
            return this.getItem(activeRole).toLowerCase().slice(1, -1);
        }
        return null;
    }

    // *[Logged user business unit]*

    getUnitId(): number | null {
        const value = Number(this.getItem('unitId'));

        if (value == 0 || isNaN(value)) return null;
        else return value;
    }

    getCurrentSchoolYearId() : number | null {
    const value = Number(this.getItem('currentSchoolYear'));
    if (value == 0 || isNaN(value)) return null;
    else return value;

    }

    key(index: number): string | null {
        return this.storage.key(index);
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(key, value.trim());
    }
}
