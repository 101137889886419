/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [];

export const suAdminNavigation: FuseNavigationItem[] = [
    {
        title: 'Super Administrator',
        type: 'group',
        children: [
            {
                title: 'Home',
                type: 'basic',
                icon: 'heroicons_outline:home',
                link: 'home',
            },
            {
                id: 'Dashboard',
                title: 'Dashboard',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: 'dashboard',
            },
            {
                id: 'countries',
                title: 'Countries',
                type: 'basic',
                icon: 'heroicons_outline:globe',
                link: 'countries',
            },
            {
                id: 'cities',
                title: 'Cities',
                type: 'basic',
                icon: 'heroicons_outline:location-marker',
                link: 'cities',
            },
            {
                id: 'event-types',
                title: 'EventTypes',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: 'eventtypes',
            },
            {
                title: 'Institutions',
                type: 'collapsable',
                icon: 'heroicons_outline:library',
                children: [
                    {
                        id: 'preschoolInstitutions',
                        title: 'PreschoolInstitutions',
                        type: 'basic',
                        icon: 'heroicons_outline:academic-cap',
                        link: 'institutions/preschools',
                    },
                    {
                        id: 'businessUnits',
                        title: 'BusinessUnits',
                        type: 'basic',
                        icon: 'heroicons_outline:office-building',
                        link: 'institutions/businessunits',
                    },
                ],
            },
            {
                title: 'Programs',
                type: 'collapsable',
                icon: 'heroicons_outline:clipboard-list',
                children: [
                    {
                        id: 'primaryprograms',
                        title: 'PrimaryPrograms',
                        type: 'basic',
                        icon: 'heroicons_outline:dots-vertical',
                        link: 'programs/primaryprograms',
                    },
                    {
                        id: 'developmentalprograms',
                        title: 'DevelopmentalPrograms',
                        icon: 'heroicons_outline:dots-vertical',
                        type: 'basic',
                        link: 'programs/developmentalprograms',
                    },
                ],
            },
            {
                id: 'schoolyears',
                title: 'SchoolYears',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: 'school-years',
            },
            {
                id: 'documenttypes',
                title: 'DocumentTypes',
                type: 'basic',
                icon: 'heroicons_outline:folder-open',
                link: 'documents',
            },
            {
                id: 'evaluationitems ',
                title: 'EvaluationItems',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: 'evaluation',
            },
            {
                id: 'activitytypes',
                title: 'ActivityTypes',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-2',
                link: 'activity-types',
            },
            {
                title: 'MealTypes',
                type: 'basic',
                icon: 'mat_solid:restaurant',
                link: 'all-meal-types',
            },
        ],
    },
];

export const parentNavigation: FuseNavigationItem[] = [
    {
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: 'home',
    },
    {
        title: 'Parent',
        type: 'group',
        children: [
            {
                title: 'Enrollments',
                type: 'basic',
                icon: 'heroicons_outline:table',
                link: 'enrollments',
            },
            {
                title: 'Documents',
                type: 'basic',
                icon: 'heroicons_outline:clipboard',
                link: 'documents',
            },
            {
                title: 'MonitoringAndAdjustment',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: 'monitoring&adjustment',
            },
        ],
    },
];

export const educatorNavigation: FuseNavigationItem[] = [
    {
        title: 'Home',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: 'home',
    },
    {
        title: 'Educator',
        type: 'group',
        children: [
            {
                title: 'Announcements',
                type: 'basic',
                icon: 'heroicons_outline:speakerphone',
                link: 'educator-announcements',
            },
            {
                title: 'Children',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: 'children&records',
            },
            {
                title: 'MonitoringAndAdjustment',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: 'monitoring&adjustment/list',
            },
            {
                title: 'WeeklyActivities',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: 'weeklyActivities',
            },
            {
                title: 'Payments',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'educator-payments',
            },
            {
                title: 'Galleries',
                type: 'basic',
                icon: 'heroicons_outline:folder-open',
                link: 'gallery',
            },
            {
                title: 'Attendances',
                type: 'basic',
                icon: 'heroicons_outline:user',
                link: 'educator-attendances',
            },
            {
                title: 'AttendancesStats',
                type: 'basic',
                icon: 'heroicons_outline:chart-bar',
                link: 'educator-attendances-stats',
            },
            {
                title: 'Placeholder',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: 'placeholders'
            },
            {
                title: 'CallLogs',
                type: 'basic',
                icon: 'heroicons_outline:phone-incoming',
                link: 'call-logs'
            },
            {
                title: 'DataEntry',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: 'children-management',
            },
        ],
    },
];

export const institutionManagerNavigation: FuseNavigationItem[] = [
    {
        title: 'PreschoolInstitutionManager',
        type: 'group',
        children: [
            {
                title: 'Home',
                type: 'basic',
                icon: 'heroicons_outline:home',
                link: 'home',
            },
            {
                title: 'Notices',
                type: 'collapsable',
                icon: 'heroicons_outline:speakerphone',
                children: [
                    {
                        title: 'Announcements',
                        type: 'basic',
                        icon: 'mat_solid:info',
                        link: 'announcements',
                    },
                    {
                        title: 'PublicCalls',
                        type: 'basic',
                        icon: 'mat_solid:gavel',
                        link: 'announcements-calls',
                    },
                    {
                        title: 'PublicTenders',
                        type: 'basic',
                        icon: 'mat_solid:text_snippet', 
                        link: 'announcements-tenders',
                    },
                ],
            },
            // {
            //     title: 'Announcements',
            //     type: 'basic',
            //     icon: 'heroicons_outline:speakerphone',
            //     link: 'announcements',
            // },
            {
                title: 'Events',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: 'events',
            },
            {
                title: 'Galleries',
                type: 'basic',
                icon: 'heroicons_outline:folder-open',
                link: 'gallery',
            },
            {
                title: 'Documents',
                type: 'basic',
                icon: 'heroicons_outline:clipboard',
                link: 'institutiondocuments',
            },
            {
                type: 'divider',
            },
            {
                title: 'Dashboard',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: 'dashboard',
            },
            {
                title: 'BusinessOverview',
                type: 'basic',
                icon: 'heroicons_outline:credit-card',
                link: 'manager-payments',
            },
            {
                title: 'AttendancesStats',
                type: 'basic',
                icon: 'heroicons_outline:chart-bar',
                link: 'attendances-stats',
            },
            {
                type: 'divider',
            },
            {
                title: 'Children',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: 'children-management',
            },
            {
                title: 'Parents',
                type: 'basic',
                icon: 'supervised_user_circle',
                link: 'parents-management',
            },
            {
                type: 'divider',
            },
            {
                title: 'Staff',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: 'staff-management'
            },
            {
                title: 'GroupsAndEducators',
                type: 'basic',
                icon: 'heroicons_outline:tag',
                link: 'preschoolgroup-employees',
            },
            {
                title: 'WeeklyActivities',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: 'weekly-activities',
            },
            {
                type: 'divider',
            },
            {
                title: 'Enrollments',
                type: 'basic',
                icon: 'heroicons_outline:table',
                link: 'enrollmentslist',
            },
            {
                title: 'DevelopmentalProgramEnrollments',
                type: 'basic',
                icon: 'history_edu',
                link: 'developmentalenrollments',
            },
            {
                title: 'Placeholder',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: 'placeholderrequests',
            },
            {
                title: 'MonitoringAndAdjustment',
                type: 'basic',
                icon: 'heroicons_outline:document-search',
                link: 'monitoring&adjustmentlist',
            },
            {
                title: 'CallLogs',
                type: 'basic',
                icon: 'heroicons_outline:phone-incoming',
                link: 'call-logs'
            }
        ],
    },
];

export const institutionAdminNavigation: FuseNavigationItem[] = [
    {
        title: 'PreschoolInstitutionAdministrator',
        type: 'group',
        children: [
            {
                title: 'Home',
                type: 'basic',
                icon: 'heroicons_outline:home',
                link: 'home',
            },
            {
                title: 'Announcements',
                type: 'basic',
                icon: 'heroicons_outline:speakerphone',
                link: 'announcements',
            },
            {
                title: 'Events',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: 'events',
            },
            {
                title: 'Galleries',
                type: 'basic',
                icon: 'heroicons_outline:folder-open',
                link: 'gallery',
            },
            {
                type: 'divider',
            },
            {
                title: 'Dashboard',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: 'dashboard',
            },
            {
                title: 'Children',
                type: 'basic',
                icon: 'heroicons_outline:book-open',
                link: 'children-management',
            },
            {
                title: 'Staff',
                type: 'basic',
                icon: 'heroicons_outline:user-circle',
                link: 'staff-management',
            },
            {
                title: 'Parents',
                type: 'basic',
                icon: 'supervised_user_circle',
                link: 'parents-management',
            },
            {
                type: 'divider',
            },
            {
                title: 'Settings',
                type: 'collapsable',
                icon: 'heroicons_outline:cog',
                    children: [
                        {
                            title: 'InstitutionConfiguration',
                            type: 'basic',
                            icon: 'heroicons_outline:library',
                            link: 'institution-config',
                        },
                        {
                            title: 'BusinessUnits',
                            type: 'basic',
                            icon: 'heroicons_outline:office-building',
                            link: 'business-units',
                        },
                        {
                            title: 'Documents',
                            type: 'basic',
                            icon: 'heroicons_outline:document-text',
                            link: 'document-types',
                        },
                        {
                            title: 'Programs',
                            type: 'basic',
                            icon: 'heroicons_outline:clipboard-list',
                            link: 'programs',
                        },
                        {
                            title: 'Groups',
                            type: 'basic',
                            icon: 'heroicons_outline:tag',
                            link: 'groups',
                        },
                        {
                            title: 'EvaluationItems',
                            type: 'basic',
                            icon: 'heroicons_outline:document-search',
                            link: 'evaluation-items',
                        },
                        {
                            title: 'Activities',
                            type: 'basic',
                            icon: 'heroicons_outline:puzzle',
                            link: 'activities',
                        },
                        {
                            title: 'MealTypes',
                            type: 'basic',
                            icon: 'mat_solid:restaurant',
                            link: 'meal-types',
                        },
                    ],
            },

        ],
    },
];

export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];

// export const landingPageNavigation: FuseNavigationItem[] = [
//     {
//         id: 'homepage',
//         title: 'Home',
//         type: 'basic',
//         icon: 'heroicons_outline:home',
//         link: '',
//     },
//     {
//         id: 'aboutus',
//         title: 'AboutUs',
//         type: 'basic',
//         icon: 'heroicons_outline:information-circle',
//         link: '/example',
//     },
//     {
//         id: 'vrtici',
//         title: 'PreschoolInstitutions',
//         type: 'basic',
//         icon: 'heroicons_outline:office-building',
//         link: '/vrtici',
//     },
//     {
//         id: 'contact',
//         title: 'Contact',
//         type: 'basic',
//         icon: 'heroicons_outline:phone',
//         link: '/example',
//     },
// ]
