import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } from 'app/services/storage.service';
import { activeRole, institutionId } from 'app/constants/constants';
import { Store } from '@ngxs/store';
import { AuthReset, AuthState } from 'app/stores/all';
import { StateResetAll } from 'ngxs-reset-plugin';

@Component({
    selector: 'app-e401',
    templateUrl: './e401.component.html',
    styleUrls: ['./e401.component.scss'],
})
export class E401Component implements OnInit {
    goBackNum = -2;
    constructor(private _location: Location,
                private _storageService: StorageService,
                private _store: Store,
                private _router: Router)
    {
    }
    ngOnInit(): void {
        const item = history.state;
        
        if(item.goBack){
            this.goBackNum = item.goBack;
        }

        const user = this._store.selectSnapshot(AuthState.details);
        if(user && user.InstitutionId && user.ActiveRole){
            this._storageService.setItem(institutionId, user.InstitutionId)
            this._storageService.setItem(activeRole, `"${user.ActiveRole}"`)
        }
    }

    goBack(){
        const user = this._store.selectSnapshot(AuthState.details);
        if(!user || !user.PreschoolInstitutions || !user.ActiveRole){
            this._store.dispatch(new AuthReset());
            this._store.dispatch(new StateResetAll());
            this._router.navigate(['sign-in'])
            return;
        }
        this._location.historyGo(this.goBackNum)
    }
}
