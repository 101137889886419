import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import {
    compactNavigation,
    educatorNavigation,
    futuristicNavigation,
    horizontalNavigation,
    institutionAdminNavigation,
    institutionManagerNavigation,
    suAdminNavigation,
} from 'app/mock-api/common/navigation/data';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'app/services/storage.service';
import { ActiveRoleEnum } from 'app/models/enums/roles.enum';
import {
    EmployeesService,
    PlaceholderRequestsService,
    SignalRService,
} from 'app/services/all';
import { PlaceholderGetNotificationForChildRequest } from 'app/models/requests/placeholder-requests.request';
import { forkJoin, map } from 'rxjs';
import { DevelopmentalProgramEnrollmentsService } from 'app/services/developmental-program-enrollments.service';
import { activeRole as aRole }  from 'app/constants/constants';

@Injectable({
    providedIn: 'root',
})
export class NavigationMockApi {
    private readonly _compactNavigation: FuseNavigationItem[] =
        compactNavigation;
    private _defaultNavigation: FuseNavigationItem[] = [];
    private readonly _futuristicNavigation: FuseNavigationItem[] =
        futuristicNavigation;
    private readonly _horizontalNavigation: FuseNavigationItem[] =
        horizontalNavigation;

    private readonly _suAdminNavigation: FuseNavigationItem[] =
        suAdminNavigation;

    private readonly _instituionAdminNavigation: FuseNavigationItem[] =
        institutionAdminNavigation;

    private readonly _instituionManagerNavigation: FuseNavigationItem[] =
        institutionManagerNavigation;
    private readonly _educatorNavigation: FuseNavigationItem[] =
        educatorNavigation;

    private readonly _parentNavigation: FuseNavigationItem[] = [];
    /**
     * Constructor
     */
    constructor(
        private _fuseMockApiService: FuseMockApiService,
        public _translateService: TranslateService,
        public _storageService: StorageService,
        private _placeholderRequestsService: PlaceholderRequestsService,
        private _employeesService: EmployeesService,
        private _developmentalProgramEnrollmentService: DevelopmentalProgramEnrollmentsService
    ) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ INIT NAVIGATION BY ROLE - GET
        // -----------------------------------------------------------------------------------------------------

        let activeRole = localStorage.getItem(aRole);
        if(activeRole){
            activeRole = activeRole.toLowerCase().slice(1, -1)
        }
        //TODO - check if educator is really main educator

        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------

        const generateNavigation = (
            navigation: FuseNavigationItem[]
        ): [number, string | any] => {
            this._defaultNavigation = navigation;

            this._compactNavigation.forEach((compactNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === compactNavItem.id) {
                        compactNavItem.children = cloneDeep(
                            defaultNavItem.children
                        );
                    }
                });
            });

            // Fill futuristic navigation children using the default navigation
            this._futuristicNavigation.forEach((futuristicNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === futuristicNavItem.id) {
                        futuristicNavItem.children = cloneDeep(
                            defaultNavItem.children
                        );
                    }
                });
            });

            // Fill horizontal navigation children using the default navigation
            this._horizontalNavigation.forEach((horizontalNavItem) => {
                this._defaultNavigation.forEach((defaultNavItem) => {
                    if (defaultNavItem.id === horizontalNavItem.id) {
                        horizontalNavItem.children = cloneDeep(
                            defaultNavItem.children
                        );
                    }
                });
            });

            // Return the response
            return [
                200,
                {
                    compact: cloneDeep(this._defaultNavigation),
                    default: cloneDeep(this._defaultNavigation),
                    futuristic: cloneDeep(this._defaultNavigation),
                    horizontal: cloneDeep(this._defaultNavigation),
                },
            ];
        };

        this._fuseMockApiService.onGet('api/common/navigation').reply(() => {
            if (activeRole == ActiveRoleEnum.SuperAdministrator)
                return generateNavigation(this._suAdminNavigation);

            if (activeRole == ActiveRoleEnum.PreschoolInstitutionAdministrator)
                return generateNavigation(this._instituionAdminNavigation);

            if (activeRole == ActiveRoleEnum.PreschoolInstitutionManager) {
                this._defaultNavigation = this._instituionManagerNavigation;

                return forkJoin([
                    this._placeholderRequestsService.getNotificationForChild(
                        new PlaceholderGetNotificationForChildRequest(
                            this._storageService.getInstitutionId(),
                            []
                        )
                    ),
                    this._developmentalProgramEnrollmentService.pendingEnrollments(
                        this._storageService.getInstitutionId()
                    ),
                ]).pipe(
                    map(([placeholderResponse, pendingEnrollmentResponse]) => {
                        const totalCount = (placeholderResponse.find(
                            (item) => item?.totalCount
                        )?.totalCount ?? 0) as number;
                        const pendingCount = pendingEnrollmentResponse;

                        const managerNavigationGroup =
                            this._instituionManagerNavigation.find(
                                (e) => e.title == 'PreschoolInstitutionManager'
                            );
                        if (
                            managerNavigationGroup &&
                            managerNavigationGroup.children
                        ) {
                            const placeholderNavItem =
                                managerNavigationGroup.children.find(
                                    (c) => c.title == 'Placeholder'
                                );
                            if (placeholderNavItem) {
                                if (totalCount > 0) {
                                    placeholderNavItem.badge = {
                                        title: totalCount.toString(),
                                        classes:
                                            'fuse-vertical-navigation-item-badge-content bg-orange-color w-5 h-5 text-black rounded-full',
                                    };
                                } else {
                                    placeholderNavItem.badge = null;
                                }
                            }

                            const developmentalEnrollmentNavItem =
                                managerNavigationGroup.children.find(
                                    (c) =>
                                        c.title ==
                                        'DevelopmentalProgramEnrollments'
                                );
                            if (developmentalEnrollmentNavItem) {
                                if (pendingCount > 0) {
                                    developmentalEnrollmentNavItem.badge = {
                                        title: pendingCount.toString(),
                                        classes:
                                            'fuse-vertical-navigation-item-badge-content bg-orange-color w-5 h-5 text-white rounded-full',
                                    };
                                } else {
                                    developmentalEnrollmentNavItem.badge = null;
                                }
                            }

                            return generateNavigation(
                                this._instituionManagerNavigation
                            );
                        }
                    })
                );
            }

            if (activeRole == ActiveRoleEnum.Educator) {
                this._defaultNavigation = this._educatorNavigation;

                return forkJoin([
                    this._placeholderRequestsService.getNotificationForChild(
                        new PlaceholderGetNotificationForChildRequest(
                            this._storageService.getInstitutionId(),
                            []
                        )
                    ),
                    this._employeesService.isCoordinator(),
                ]).pipe(
                    map(([notificationData, coordinatorData]) => {
                        const totalCount = (notificationData.find(
                            (item) => item?.totalCount
                        )?.totalCount ?? 0) as number;

                        const educatorNavigationGroup =
                            this._educatorNavigation.find(
                                (e) => e.title == 'Educator'
                            );
                        if (
                            educatorNavigationGroup &&
                            educatorNavigationGroup.children
                        ) {
                            const placeholderNavItem =
                                educatorNavigationGroup.children.find(
                                    (c) => c.title == 'Placeholder'
                                );
                            if (placeholderNavItem) {
                                if (totalCount > 0) {
                                    placeholderNavItem.badge = {
                                        title: totalCount.toString(),
                                        classes:
                                            'fuse-vertical-navigation-item-badge-content w-5 h-5 badge-color text-black rounded-full',
                                    };
                                } else {
                                    placeholderNavItem.badge = null;
                                }
                            }
                        }

                        const isMain = coordinatorData;
                        if (isMain) {
                            const menu = this._defaultNavigation.find(
                                (x) => x.title == 'Menu'
                            );
                            if (menu == null) {
                                this._defaultNavigation.push({
                                    title: 'Menu',
                                    type: 'basic',
                                    icon: 'mat_solid:restaurant',
                                    link: 'menu',
                                });
                            }
                        }

                        return generateNavigation(this._educatorNavigation);
                    })
                );
            }

            if (activeRole == ActiveRoleEnum.Parent)
                return generateNavigation(this._parentNavigation);
            return generateNavigation(this._defaultNavigation);
        });
    }
}
