<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div
    class="flex flex-auto justify-center w-full sm:p-4 md:p-8 bg-gray-200 dark:bg-card"
>
    <div
        class="flex flex-col items-center flex-auto min-w-0 max-w-360 sm:rounded-xl shadow-2xl dark:shadow-none overflow-hidden"
    >
        <!-- Header -->
        <div
            class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 bg-card border-b dark:bg-default print:hidden"
        >
            <ng-container *ngIf="!isScreenSmall">
                <!-- Logo -->
                <div class="flex items-center mx-2 lg:ml-5 lg:mr-8">
                    <div class="hidden lg:flex">
                        <!-- Light version -->
                        <button
                            [routerLink]="
                                !displaySpecificHeaderStuff
                                    ? ['../waiting-list']
                                    : ['../home']
                            "
                        >
                            <img
                                class="dark:hidden w-10"
                                [src]="logoTextUrl"
                                alt="Logo image"
                            />
                        </button>
                        <!-- Dark version -->
                        <button
                            [routerLink]="
                                !displaySpecificHeaderStuff
                                    ? ['../waiting-list']
                                    : ['../home']
                            "
                        >
                            <img
                                class="hidden dark:flex w-10"
                                [src]="logoTextUrl"
                                alt="Logo image"
                            />
                        </button>
                    </div>
                    <!-- Small version -->
                    <button
                        [routerLink]="
                            !displaySpecificHeaderStuff
                                ? ['../waiting-list']
                                : ['../home']
                        "
                    >
                        <img
                            class="flex lg:hidden w-8"
                            [src]="logoTextUrl"
                            alt="Logo image"
                        />
                    </button>
                </div>
                <!-- Horizontal navigation -->
                <fuse-horizontal-navigation
                    class="mr-2"
                    [name]="'mainNavigation'"
                    [navigation]="navigation?.horizontal"
                ></fuse-horizontal-navigation>
            </ng-container>
            <!-- Navigation toggle button -->
            <ng-container *ngIf="isScreenSmall">
                <button
                    class="mr-2 ml-5"
                    mat-icon-button
                    [routerLink]="
                        !displaySpecificHeaderStuff
                            ? ['../waiting-list']
                            : ['../home']
                    "
                >
                    <img
                        class="flex lg:hidden w-8"
                        [src]="logoTextUrl"
                        alt="Logo image"
                    />
                </button>
            </ng-container>
            <!-- Components -->
            <div
                class="flex items-center pl-2 pe-2 ml-auto space-x-1 sm:space-x-2"
            >
                <a
                    mat-icon-button
                    [routerLink]="
                        !displaySpecificHeaderStuff
                            ? ['../waiting-list']
                            : ['../home']
                    "
                    ><mat-icon [svgIcon]="'heroicons_outline:home'"></mat-icon>
                </a>
                <a
                    *ngIf="displaySpecificHeaderStuff"
                    mat-icon-button
                    [routerLink]="['../calendar']"
                    ><mat-icon
                        [svgIcon]="'heroicons_outline:calendar'"
                    ></mat-icon>
                </a>
                <a
                    mat-icon-button
                    [routerLink]="['../parents-children']"
                    ><mat-icon [svgIcon]="'heroicons_outline:user-group'"></mat-icon>
                </a>
                <a
                    mat-icon-button
                    [routerLink]="['../waiting-list']"
                    ><mat-icon [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
                </a>
                <messages *ngIf="displaySpecificHeaderStuff"></messages>
                <notifications
                    *ngIf="displaySpecificHeaderStuff"
                ></notifications>

                <languages></languages>
                <user></user>
            </div>
        </div>

        <!-- Content -->
        <div class="flex flex-col flex-auto w-full bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>

        <!-- Footer -->
        <div
            class="relative flex flex-0 items-center justify-start w-full h-16 sm:h-20 px-6 sm:px-8 z-49 bg-card border-t dark:bg-default print:hidden"
        >
            <span class="font-medium text-secondary"
                >Breakpoint &copy; {{ currentYear }}</span
            >
        </div>
    </div>
</div>
