import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from '@ngx-translate/core';
import { AppInitializerFactory } from './app.initializer.factory';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { States } from './stores/all';
import { AuthService, StorageService } from './services/all';
import { QuillModule } from 'ngx-quill';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getTranslatePaginatorIntl } from './utilities/paginator.intl';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MY_FORMATS } from './constants/date-format';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { PipesModule } from '@fuse/pipes/pipes.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
        NgxsStoragePluginModule.forRoot({
            key: ['auth.token'],
        }),
        NgxsModule.forRoot(States.all),
        NgxsResetPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot({}),
        ToastNoAnimationModule.forRoot(),
        QuillModule.forRoot(),
        BrowserAnimationsModule,
        SlickCarouselModule,
        PipesModule
    ],
    providers: [
        {
            multi: true,
            provide: APP_INITIALIZER,
            useFactory: AppInitializerFactory,
            deps: [TranslateService, StorageService, Injector, AuthService],
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { panelClass: ['mycsssnackbartest'] },
        },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: MatPaginatorIntl, useValue: getTranslatePaginatorIntl() },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 2500 },
        },
        { provide: MAT_DATE_LOCALE, useValue: 'sr-Latn' },
        provideMomentDateAdapter( MY_FORMATS, { useUtc: true }),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}