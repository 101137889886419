import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppUserModel } from 'app/models/app-user.model';
import {
    AppUserCheckExistRequest,
    AppUserInsertProfilePictureRequest,
    AppUserInsertRequest,
    AppUsersDeactivateRequest,
    AppUsersGetRequest,
    GetByUserIdRequest,
    AppUserGetByUserName,
    AppUserInsertRoleRequest,
    AppUserUpdatePasswordRequest,
    AppUserUpdateRequest,
    AppUserUpdateEmailRequest,
    AppUserUpdateLanguageRequest,
    AppUserRoleUpdateRequest,
    AppUserInstitutionRoleCheckRequest,
} from 'app/models/requests/all';
import { Observable } from 'rxjs';

import { ApiService, StorageService } from './all';
import { SimpleItemModel } from 'app/models/simple-item.model';

@Injectable({ providedIn: 'root' })
export class AppUsersService extends ApiService {
    constructor(
        protected override store: Store,
        protected override httpClient: HttpClient,
        protected override storageService: StorageService
    ) {
        super(store, httpClient, storageService);
    }

    get(request: AppUsersGetRequest): Observable<any> {
        return this.sendGetRequest<any>('appusers/filter', request);
    }

    getAvailableRoles() {
        return this.sendGetRequest<any>('appusers/available-roles');
    }

    getEducatorsBusinessUnits() {
        return this.sendGetRequest<SimpleItemModel[]>(
            'appusers/geteducatorsbusinessunits'
        );
    }

    getByUserName(request: AppUserGetByUserName) {
        return this.sendGetRequest<AppUserModel>(
            'appusers/getbyusername',
            request
        );
    }

    getPreschoolInstitutions(request: GetByUserIdRequest) {
        return this.sendGetRequest<any>(
            'appusers/getpreschoolinstitutionsbyuserid',
            request
        );
    }

    insert(request: AppUserInsertRequest) {
        const params: any = request?.toParams();
        const body: any = request?.toBody();

        return this.httpClient.post<any>(
            this.withBaseApiUrl('appusers'),
            body,
            {
                headers: this.prepareHeaders(),
                params: params,
            }
        );
    }

    insertProfilePicture(request: AppUserInsertProfilePictureRequest) {
        return this.sendPutRequest('appusers/insertprofilepicture', request);
    }

    insertUserRole(request: AppUserInsertRoleRequest) {
        return this.sendPostRequest<any>('appusers/insertappuserrole', request);
    }

    uploadProfilePicture(formData: FormData) {
        return this.httpClient.post<any>(
            this.withBaseApiUrl('appusers/uploadprofilepicture'),
            formData,
            {
                headers: this.prepareUploadHeaders(),
            }
        );
    }

    update(request: AppUserUpdateRequest) {
        return this.sendPutRequest('appusers', request);
    }

    updatePassword(request: AppUserUpdatePasswordRequest) {
        return this.sendPutRequest<{ message: string }>(
            'appusers/changepassword',
            request
        );
    }

    requestEmailUpdate(request: AppUserUpdateEmailRequest) {
        return this.sendPutRequest<{ message: string }>(
            'appusers/request-email-update',
            request
        );
    }

    userExist(request: AppUserCheckExistRequest) {
        return this.sendGetRequest<any>('appusers/userexist', request);
    }

    changeUserLanguage(request: AppUserUpdateLanguageRequest) {
        return this.sendPostRequest('appusers/change-user-language', request);
    }

    getUsersLanguage() {
        return this.sendGetRequestWithResponseTypeText(
            'appusers/get-user-language'
        );
    }

    deactivate(request: AppUsersDeactivateRequest) {
        return this.sendDeleteRequest<any>('appusers/deactivate', request);
    }

    updateUserRoles(request: AppUserRoleUpdateRequest) {
        return this.sendPutRequest('appusers/updatestaffroles', request);
    }

    checkUserInstitutionRole(request: AppUserInstitutionRoleCheckRequest) {
        return this.sendGetRequest<boolean>(
            'appusers/checkuserinstitutionrole',
            request
        );
    }
}
